<template>
  <div>
    <b-card no-body class="card-animate overflow-hidden">
        <div class="position-absolute start-0 widget-pattern" style="z-index: 0;">
            <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 120" width="200"
                height="120">
                <path id="Shape 8" class="s0"
                    d="m189.5-25.8c0 0 20.1 46.2-26.7 71.4 0 0-60 15.4-62.3 65.3-2.2 49.8-50.6 59.3-57.8 61.5-7.2 2.3-60.8 0-60.8 0l-11.9-199.4z" />
            </svg>
        </div>
        <b-card-body class="p-3" style="z-index:1 ;">
            <div class="d-flex align-items-center">
                <div class="flex-grow-1 overflow-hidden">
                    <p class="text-uppercase fw-medium text-muted text-truncate mb-3">{{title}}</p>
                    <h4 class="fs-22 fw-semibold ff-secondary mb-0">
                        <count-to :startVal="0" :endVal="figure" :duration="5000"></count-to>
                    </h4>
                </div>
                <div class="flex-shrink-0">
                    <apexchart class="apex-charts" dir="ltr" width="105px" height="90px" 
                    :series='[$filters.getPercent(figure, total)]'
                        :options="{ ...chartOptions }"></apexchart>
                </div>
            </div>
        </b-card-body>
    </b-card>
  </div>
</template>

<script>

import { CountTo } from "vue3-count-to";
import VueApexCharts from "vue3-apexcharts";

export default {
    components:{
      apexchart: VueApexCharts,
      CountTo,
    },
    props:{
        title:{
            default: ""
        },
        countStart:{
            default: 0
        },
        countEnd:{
            default: 0
        },
        figure:{
            default: 0
        },
        total:{
            default: 0
        },
    },
    data(){
        return {
            chartcolors: {
                colors: ["#f06548"]
            },
            chartOptions: {
                chart: {
                type: "radialBar",
                width: 105,
                sparkline: {
                    enabled: true,
                }
                },
                dataLabels: {
                    enabled: false,
                },
                plotOptions: {
                    radialBar: {
                        hollow: {
                        margin: 0,
                        size: '70%'
                        },
                        track: {
                        margin: 1
                        },
                        dataLabels: {
                        show: true,
                        name: {
                            show: false
                        },
                        value: {
                            show: true,
                            fontSize: '16px',
                            fontWeight: 600,
                            offsetY: 8,
                        }
                        }
                    },
                },
                colors: ["#0ab39c"]
            }
        }
    }
}
</script>

<style scoped>
.widget-pattern .s0 {
    opacity: .05;
    fill: var(--bs-success);
}
</style>